import LoginModal from './core__loginModal';
import React from 'react';

const LoginModal_Copy = props => {
  return (
    <LoginModal
      {...props}
      showJNModal={true}
      successRedirectText="text.browse.website"
      usernamePrefix="+27"
      usernamePrefixValue="27"
      usernameLabel="text.cell.phone.number"
      usernameValidationKey="text.validphonenumber"
    />
  );
};
export { LoginModal_Copy as LoginModal };
