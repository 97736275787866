import styled from 'styled-components';
import {
  MStyles,
  GreenIcon,
  Total,
  InfoText,
  SeeDetails,
  Separator,
  Row,
  Amount,
  ModalIcon,
  MContentContainer,
  MContentTitle,
  MContentSubtitle,
  MContentLink,
  ModalTotal,
  CrossIcon,
} from 'CORE__UI/apps/BankingApp/core__successful-withdraw-modal';
import { FUNCTIONAL, FONT, BACKGROUND } from 'UI/globals/colours';

const MStyles_EX = {
  ...MStyles,
  modal: {
    ...MStyles.modal,
    border: 'unset',
    color: FONT.black.standard,
    backgroundColor: BACKGROUND.surface,
  },
};

const GreenIcon_EX = styled(GreenIcon)`
  color: ${FUNCTIONAL.success};
`;

const Separator_EX = styled(Separator)`
  border-color: ${BACKGROUND.surfaceDivider};
`;

const CrossIcon_EX = styled(CrossIcon)`
  color: ${FUNCTIONAL.negative};
`;

const SeeDetails_EX = styled(SeeDetails)`
  color: ${FONT.darkMute};
`;

export {
  MStyles_EX as MStyles,
  GreenIcon_EX as GreenIcon,
  Total,
  InfoText,
  SeeDetails_EX as SeeDetails,
  Separator_EX as Separator,
  Row,
  Amount,
  ModalTotal,
  MContentLink,
  MContentSubtitle,
  MContentTitle,
  MContentContainer as MContainer,
  ModalIcon as MIStyle,
  CrossIcon_EX as CrossIcon,
};
