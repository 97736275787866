import React from 'react';
import InputText from 'CORE__UI/forms/InputText/core__inputText';
import styled from 'styled-components';
import { FONT } from 'UI/globals/colours';

const InputText_EX = styled(InputText)`
  ::placeholder {
    opacity: 0.8;
    color: ${FONT.black.standard};
    font-size: 16px;
  }
`;

const InputText_Copy = props => {
  return <InputText_EX {...props} showJNStyle={false} />;
};
export default InputText_Copy;
