import styled, { css } from 'styled-components';
import { BACKGROUND, FONT, GREYS } from 'UI/globals/colours';
import {
  ITC,
  InputWrapper,
  Textbox,
  L,
  HT,
  ValidText,
  GuideText,
  ErrorText,
  WarningText,
  TB,
  LC,
} from 'CORE__UI/forms/InputText/core__inputTextStyles';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const ITC_EX = styled(ITC)`
  width: ${({ width }) => width};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  ${({ noInput }) =>
    noInput &&
    `display: flex;
    margin-bottom: 8px;
  `}
`;

const Textbox_EX = styled(Textbox)`
  background-color: ${BACKGROUND.surfaceOverlay};
  border: 1px solid ${BACKGROUND.surfaceOverlay};
  ::placeholder {
    color: ${FONT.black.disabled};
  }
  font-weight: normal;
  ${({ noInput }) =>
    noInput &&
    `background-color: ${FONT.white.standard};
       border: none;
  `}
  ${({ lightBgColor }) =>
    lightBgColor &&
    css`
      background-color: ${FONT.white.standard};
    `}
  ${({ inactive, disabled }) =>
    inactive &&
    disabled &&
    css`
      background-color: ${props =>
        props.theme.dark ? GREYS.silverD : FONT.white.standard};
      color: ${props => (props.isIphone ? FONT.black.standard : FONT.darkMute)};
    `}
`;

const HT_EX = styled(HT)`
  color: ${FONT.black.label};
  &:hover {
    color: ${FONT.black.label};
  }
`;

const TB_UI = styled(TB)`
  justify-content: ${props => (props?.flexAlign ? `space-between` : `initial`)};
  ${({ noInput }) =>
    noInput &&
    `
  width: 31%;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 35%;
  }
  `}
  @media (max-width: ${BREAKPOINT_S}) {
    ${({ extraWidth }) => extraWidth && 'width: 50%;'}
  }
`;

const LC_EX = styled(LC)`
  ${({ noInput }) => noInput && 'padding: 8px;'}
`;

const InputWrapper_EX = styled(InputWrapper)`
  ${({ noInput }) => noInput && 'width: 100%;'}
`;

export {
  ITC_EX as ITC,
  InputWrapper_EX as InputWrapper,
  Textbox_EX as Textbox,
  L,
  HT_EX as HT,
  ValidText,
  GuideText,
  ErrorText,
  WarningText,
  TB_UI as TB,
  LC_EX as LC,
};
