import React from 'react';
import PropTypes from 'prop-types';
import IT from 'UI/forms/InputText';
import ModalButton from 'UI/buttons/ModalButton';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';
import { GLOBAL_CONSTANTS, GLOBAL_PATH } from 'Services/global/core__constants';
import {
  H,
  D,
  S,
  C,
  E,
  MB,
  MStyles,
  ModalButtonClose,
  Image,
  CR,
  J,
  Text as Text_LOCAL,
  Link as Link_LOCAL,
  CB as CB_LOCAL,
  CI as CI_LOCAL,
  OH as OH_LOCAL,
} from 'UI/apps/LoginApp/LoginModal';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { Translations } from '../../../core__login-app';
import { CloseIcon } from 'UI/globals/ModalCloseIcon';
import { Modal } from 'CORE__UI/globals/Modal/core__modal'; //caution: don't use local import
import { FSBTheme } from 'Services/core__fsb-theme';
import { getComponent } from 'Services/core__imports';
import {
  CB as CB_GLOBAL,
  CI as CI_GLOBAL,
  OH as OH_GLOBAL,
  Text as Text_GLOBAL,
  Link as Link_GLOBAL,
} from 'CORE__UI/apps/LoginApp/core__loginModal';
import { MfaVerify } from '../MfaVerify';
const CB = getComponent(CB_LOCAL, CB_GLOBAL);
const CI = getComponent(CI_LOCAL, CI_GLOBAL);
const OH = getComponent(OH_LOCAL, OH_GLOBAL);
const Text = getComponent(Text_LOCAL, Text_GLOBAL);
const Link = getComponent(Link_LOCAL, Link_GLOBAL);
import { getCookie } from 'Services/cookie/core__cookies';
import accountRedirectUrls from 'Services/global/core__accountRedirectUrls';
import { FSBCustomerSession } from 'Services/core__services';
import Spinner from 'UI/spinner/ColorSpinner';
import {
  PRST,
  B,
  PRSP,
  LoginBox,
} from 'CORE__UI/apps/LoginApp/core__loginModal';
import { GreenIcon } from 'UI/apps/BankingApp/SuccessfulWithdrawModal';
import { zeroCorrectionFlag } from 'Services/global/core__south-african-input-cleanup';
import addCustomGTMEventToDataLayer, {
  gtmEventNames,
} from 'Services/gtm/core__addCustomGTMEventToDataLayer';

// gameUrl will be use to redirect the user on particular game page after login
class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      closeModal: false,
      theme: this.props.appConfig?.theme,
      checked: false,
      username: '',
      showModal: false,
      focusFields: this.props.appConfig?.focusfields,
      gameUrl: false,
      kycApproved: false,
      userLoggedIn: false,
      isSafari: false,
    };
  }
  setModal(value) {
    this.setState(prevState => ({
      ...prevState,
      showModal: value,
    }));
  }
  componentDidUpdate(prevProps, prevState) {
    const session = FSBCustomerSession.getSession();
    if (session && Object.keys(session).length > 0 && prevState.showModal) {
      this.setModal(false);
    }
  }
  componentDidMount() {
    //check for safari browser
    try {
      this.setState(prevState => ({
        ...prevState,
        isSafari:
          /Safari/.test(window?.navigator.userAgent) &&
          /Apple Computer/.test(window?.navigator.vendor),
      }));
    } catch (error) {
      //no handling required
    }

    this.sessionUpdatedPubSubListener = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      wsData => {
        this.setState({ kycApproved: wsData.kycApproved, userLoggedIn: true });
      }
    );
    this.callLoginPopupListener = PubSub.listen(
      PubsubEvents.CallLoginPopUp,
      wsData => {
        this.props.onModalClick(
          wsData.hitting,
          'frombetslip',
          false,
          this.props.appConfig?.popupmodalcheck
        );
        this.setModal(wsData.hitting);
      }
    );
    const _this = this;
    this.openLoginModalListener = PubSub.listen(
      PubsubEvents.openLoginModal,
      data => {
        _this.props.onModalClick(true, data);
        this.setModal(true);
      }
    );
    if (sessionStorage.getItem('RegSuccess') === 'true') {
      PubSub.emit(PubsubEvents.OPEN_ID_COMPLY);
      this.props.setRegistrationSuccess(true);
    }
    if (sessionStorage.getItem('ResetSuccess') === 'true') {
      this.props.setResetSuccess(true);
    }
    this.loginModalFromCasinoListener = PubSub.listen(
      PubsubEvents.callLoginModalFromCasino,
      wsData => {
        this.props.onModalClick(wsData.modal, wsData.page);
        this.setModal(true);
        this.setState({ gameUrl: wsData?.url });
      }
    );
    this.removeLoginModalListener = PubSub.listen(
      PubsubEvents.REMOVE_LOGIN_MODEL,
      () => {
        this.setModal(false);
      }
    );

    // deeplinking in IOS if user was not login then we show sportbook landing page otherwise we show login model
    const { displayloginmodel: displayLoginModel } = this.props.appConfig;
    if (displayLoginModel && !getCookie('AppSession')) {
      PubSub.emit(PubsubEvents.openLoginModal, true);
    }
  }

  componentWillUnmount() {
    this.callLoginPopupListener?.unsubscribe();
    this.openLoginModalListener?.unsubscribe();
    this.loginModalFromCasinoListener?.unsubscribe();
    this.removeLoginModalListener?.unsubscribe();
    this.sessionUpdatedPubSubListener?.unsubscribe();
  }

  render() {
    const {
      additionallogininfo: additionalLoginInfo,
      emailnotusername: emailNotUsername,
      forgotpasswordbelow: forgotPasswordBelow,
      verifymodal: verifyModal,
      redirecttocasinopage: redirectToCasinoPage,
      enableresetmodal: enableResetModal,
      hidefirstdepositpopup,
      standalone,
      popupmodalcheck,
      phonenotusername: phoneNotUsername,
      southafricacorrectionlength: SACorrectionLength,
      regsuccesspopup: regSuccessPopup,
      regclientimage,
      regclientlink,
      image,
    } = this.props.appConfig;
    let enableLoginButton;
    if (this.props.loginObject) {
      const username = this.props.loginObject.userName?.value !== '';
      const password = this.props.loginObject.passWord?.value !== '';
      const checkbox = this.state.checked;
      if (additionalLoginInfo) {
        enableLoginButton = username && password && checkbox;
      } else {
        enableLoginButton = username && password;
      }
    }
    const RegStyles = {
      ...MStyles,
      modal: { ...MStyles.modal }, //copying the value instead of refrence here
    };
    if (regSuccessPopup) {
      RegStyles.modal.padding = '24px 0 16px 0';
    }

    const handleClose = () => {
      if (regSuccessPopup) {
        addCustomGTMEventToDataLayer({
          event: gtmEventNames.WELCOME_POP_UP_CROSS,
          data: this.props.loginObject,
        });
      }
      this.props.onRegisterModalClick(false);
    };

    const handleNavigation = gtmEvent => {
      window.location.href = `${window.location.origin}${regclientlink}`;
      addCustomGTMEventToDataLayer({
        event: gtmEvent,
        data: this.props.loginObject,
      });
      this.props.onRegisterModalClick(false);
    };
    const registrationSuccessModal = (
      <FSBTheme theme={this.state.theme}>
        <Modal
          data-test="close-modal"
          open={
            !hidefirstdepositpopup && this.props.loginObject.registrationSuccess
          }
          onClose={handleClose}
          center
          styles={RegStyles}
          closeIcon={<CloseIcon color={this.props?.color} />}
          regSuccessPopup={regSuccessPopup}
        >
          <React.Fragment>
            {verifyModal ? (
              <React.Fragment>
                <CI>
                  <I marginR={4} size={20} iconName={getIcon('TICKMARK')} />
                </CI>
                <OH>{Translations.get('text.registration.welcome')}</OH>
                <C>
                  <Text>{Translations.get('text.registration.identity')}</Text>
                </C>
                <MB
                  onClick={() => {
                    window.location.href = accountRedirectUrls.kyc;
                    this.props.onRegisterModalClick(false);
                  }}
                  label={Translations.get('mfa.button.verify')}
                />
                <ModalButtonClose
                  data-test="close-success-modal"
                  onClick={() => this.props.onRegisterModalClick(false)}
                  label={Translations.get('text.close')}
                  rank="neutral"
                />
              </React.Fragment>
            ) : popupmodalcheck &&
              !regSuccessPopup &&
              this.state.kycApproved ? (
              <React.Fragment>
                <H>{Translations.get('text.registration.success')}</H>
                <CI height={'100px'} width={'100px'} marginTop={'32px'}>
                  <I
                    marginR={4}
                    size={60}
                    iconName={getIcon('TICKMARK')}
                    color="white"
                    fontWeight="bold"
                  />
                </CI>
                <J flex>
                  <Text>
                    {Translations.get('text.registration.welcome.text')}
                  </Text>
                </J>
                <MB
                  onClick={() => {
                    window.location.href = accountRedirectUrls.banking;
                    this.props.onRegisterModalClick(false);
                  }}
                  label={Translations.get('text.make.first.deposit')}
                />
                <ModalButtonClose
                  data-test="close-success-modal"
                  onClick={() => this.props.onRegisterModalClick(false)}
                  label={Translations.get('text.withdraw.back')}
                  rank="neutral"
                />
              </React.Fragment>
            ) : popupmodalcheck &&
              !regSuccessPopup &&
              !this.state.kycApproved ? (
              <React.Fragment>
                <H>{Translations.get('text.personal.verification')}</H>
                <J flex>
                  <Text>
                    {Translations.get('text.personal.verification.text')}
                  </Text>
                </J>
                <MB
                  onClick={() => {
                    window.location.href = accountRedirectUrls.kyc;
                    this.props.onRegisterModalClick(false);
                  }}
                  label={Translations.get('text.upload')}
                />
                <J flex noBottomMargin>
                  <Text>
                    {Translations.get('text.support.contact')}{' '}
                    <Link
                      href="/contact-us"
                      onClick={() => this.props.onRegisterModalClick(false)}
                    >
                      {Translations.get('text.support.contact.link')}
                    </Link>
                  </Text>
                </J>
              </React.Fragment>
            ) : regSuccessPopup ? (
              <React.Fragment>
                <H reg={regSuccessPopup}>
                  {Translations.get('text.registration.complete')}
                </H>
                <C>
                  <Image
                    src={regclientimage}
                    reg={regSuccessPopup}
                    onClick={() =>
                      handleNavigation(gtmEventNames.WELCOME_POP_UP_IMAGE_CLICK)
                    }
                  />
                </C>
                <J reg={regSuccessPopup}>
                  <MB
                    onClick={() =>
                      handleNavigation(
                        gtmEventNames.WELCOME_POP_UP_DEPOSIT_CLAIM
                      )
                    }
                    label={Translations.get('text.deposit.claim.bonus')}
                  />
                  <ModalButtonClose
                    data-test="close-success-modal"
                    onClick={() => this.props.onRegisterModalClick(false)}
                    label={
                      this.props.successRedirectText
                        ? Translations.get(this.props.successRedirectText)
                        : Translations.get('text.close')
                    }
                    rank="neutral"
                  />
                </J>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <H>{Translations.get('text.registration.complete')}</H>
                <C>
                  <Image src={image} />
                </C>
                <C>
                  <Text>{Translations.get('text.registration.welcome')}</Text>
                </C>
                <MB
                  onClick={() => {
                    window.location.href = accountRedirectUrls.banking;
                    this.props.onRegisterModalClick(false);
                  }}
                  label={Translations.get('text.make.first.deposit')}
                />
                <ModalButtonClose
                  data-test="close-success-modal"
                  onClick={() => this.props.onRegisterModalClick(false)}
                  label={
                    this.props.successRedirectText
                      ? Translations.get(this.props.successRedirectText)
                      : Translations.get('text.close')
                  }
                  rank="neutral"
                />
              </React.Fragment>
            )}
          </React.Fragment>
        </Modal>
      </FSBTheme>
    );

    const resetSuccessModal = (
      <FSBTheme theme={this.state.theme}>
        <Modal
          data-test="close-modal"
          open={this.props.loginObject.resetSuccess}
          onClose={() => this.props.onResetModalClick(false)}
          center
          styles={MStyles}
          closeIcon={<CloseIcon color={this.props?.color} />}
        >
          <React.Fragment>
            <C style={{ textAlign: 'center' }}>
              <GreenIcon className="icon-Tickmark-in-Circle" />
            </C>
            <PRST>{Translations.get('text.reset.successfully')}</PRST>
            <PRSP>{Translations.get('text.reset.welcome')}</PRSP>
            <ModalButton
              onClick={() => {
                this.props.onResetModalClick(false);
                PubSub.emit(PubsubEvents.openLoginModal, true);
              }}
              label={Translations.get('text.login')}
            />
            <B>
              <ModalButton
                onClick={() => this.props.onResetModalClick(false)}
                label={Translations.get('text.close')}
                rank="secondary"
              />
            </B>
          </React.Fragment>
        </Modal>
      </FSBTheme>
    );

    const redirectIfNotLoggedIn = () => {
      const session = FSBCustomerSession.getSession();
      const paths = this.props.appConfig?.needsessionforurls?.split(',');

      if (
        !session &&
        !FSBCustomerSession.hasPreviousSession() &&
        paths?.length > 0
      ) {
        if (paths?.includes(window.location.pathname)) {
          window.location.href = '/casino/';
        }
      }
    };

    let label = 'user.username',
      type = 'text';
    if (emailNotUsername) {
      label = 'text.email';
      type = 'email';
    }
    if (phoneNotUsername) {
      type = 'number';
    }

    const redirectToStandAloneRegistration = () => {
      const currentUrl = window.location.href;
      const position = currentUrl.search('com');
      const slicedUrl = currentUrl.slice(0, position + 4);
      window.location.replace(slicedUrl + 'app-register/');
    };

    const loginModal = (
      <FSBTheme theme={this.state.theme}>
        {standalone ? (
          <LoginBox>
            <form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <H>{Translations.get('text.login')}</H>
              <IT
                prefix={this.props.usernamePrefix || ''}
                tabIndex="1"
                data-test="email-field"
                label={Translations.get(this.props.usernameLabel || label)}
                type={type}
                value={this.props?.loginObject?.userName?.value}
                onChange={e => {
                  this.setState(prevState => {
                    return {
                      ...prevState,
                      username: e.target.value,
                    };
                  });
                  this.props.fieldChanged(e, GLOBAL_CONSTANTS.USERNAME);
                }}
                info={Translations.get(
                  this.props?.loginObject?.userName?.error
                )}
                status={
                  this.props?.loginObject?.userName?.error ? 'error' : 'null'
                }
                focus={this.state.focusFields && this.state.showModal}
              />
              <IT
                tabIndex="2"
                autoComplete="current-password"
                type={this.state.showPassword ? 'text' : 'password'}
                data-test="password-field"
                label={Translations.get('user.password')}
                value={this.props?.loginObject?.passWord?.value}
                placeholder={this.props?.appConfig?.placeholder}
                onChange={e =>
                  this.props.fieldChanged(e, GLOBAL_CONSTANTS.PASSWORD)
                }
                info={Translations.get(
                  this.props?.loginObject?.passWord?.error
                )}
                status={
                  this.props?.loginObject?.passWord?.error ? 'error' : 'null'
                }
                helperText={
                  !forgotPasswordBelow &&
                  Translations.get('text.forgot.password')
                }
                helperLink={!forgotPasswordBelow && GLOBAL_PATH.FORGOT_PASSWORD}
                focus={this.state.focusFields && this.state.showModal}
                flexAlign={true}
                withIcon={this.props.showJNModal}
                iconClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
                isSafari={this.state.isSafari}
                defaultIcon={GLOBAL_CONSTANTS.OPEN_EYE}
                toggleIcon={GLOBAL_CONSTANTS.CLOSED_EYE}
                defaultIconState={!this.state.showPassword}
                iconSize={24}
              />
              {forgotPasswordBelow && (
                <CR>
                  <Link href={GLOBAL_PATH.FORGOT_PASSWORD} tabIndex="4">
                    {Translations.get('text.forgot.password')}
                  </Link>
                </CR>
              )}
              {this.props?.loginObject?.errorMessage && (
                <E
                  dangerouslySetInnerHTML={{
                    __html: this.props.loginObject.errorMessage,
                  }}
                ></E>
              )}
              {additionalLoginInfo && (
                <CB>
                  <input
                    type="checkbox"
                    onChange={() =>
                      this.setState({ checked: !this.state.checked })
                    }
                    checked={this.state.checked}
                  />
                  <label>{Translations.get('login.checkbox')}</label>
                </CB>
              )}
              <ModalButton
                tabIndex="3"
                disabled={
                  !additionalLoginInfo
                    ? this.props?.loginObject?.userName?.value?.length > 0 &&
                      this.props?.loginObject?.passWord?.value.length > 0 &&
                      false
                    : !enableLoginButton ||
                      !this.props.loginObject.isLoginEnable
                } // TODO
                data-test="btn-field"
                aria-label="submit login"
                label={
                  <>
                    {Translations.get('text.log.in')}
                    {!this.props.loginObject.isLoginEnable && <Spinner />}
                  </>
                }
                onClick={() => {
                  this.props.loginObject.isLoginEnable &&
                    this.props
                      .callLogin(
                        this.props?.loginObject,
                        this.props.appConfig,
                        {
                          isbetgtmtag: this.props.appConfig?.isbetgtmtag,
                          redirectToCasinoPage: redirectToCasinoPage,
                          isHio:
                            (this.props.appConfig?.validatedata &&
                              this.props.appConfig?.validatedata) ||
                            false,
                          usernamePrefix: this.props.usernamePrefixValue,
                          gameUrl: this.state.gameUrl,
                          usernameValidationKey:
                            this.props.usernameValidationKey || '',
                        },
                        {},
                        this.state.username,
                        this.props.appConfig?.formatemailtolowercase
                      )
                      .then(() =>
                        PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
                          const sessionToken = getCookie('AppSession');
                          if (sessionToken) {
                            window.location.replace(
                              this.props.appConfig.afterloginredirect +
                                sessionToken
                            );
                          } else {
                            window.location.replace(
                              window.location.href + '/error/'
                            );
                          }
                        })
                      );
                }}
              />
              <D>
                <S>{Translations.get('text.or')}</S>
              </D>
              <ModalButton
                data-test="btn-join"
                onClick={() => redirectToStandAloneRegistration()}
                label={Translations.get('text.join.now')}
                rank="secondary"
              />
              {!this.props.appConfig?.showhelp && (
                <C>
                  {Translations.get('text.need.help')}
                  {!this.props.appConfig?.hidectc && (
                    <Link
                      href={
                        this.props.appConfig?.contactusurl
                          ? this.props.appConfig?.contactusurl
                          : GLOBAL_PATH.CONTACT_US
                      }
                    >
                      {Translations.get('text.contact.us')}
                    </Link>
                  )}
                </C>
              )}
            </form>
          </LoginBox>
        ) : (
          <Modal
            data-test="close-modal"
            open={!standalone && this.state.showModal}
            onClose={() => {
              this.setModal(false);
              this.props.onModalClick(false, '', redirectToCasinoPage);
              redirectIfNotLoggedIn();
            }}
            center
            styles={MStyles}
            closeIcon={<CloseIcon color={this.props?.color} />}
            key="loginModal"
          >
            <form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <H>{Translations.get('text.login')}</H>
              <IT
                prefix={this.props.usernamePrefix || ''}
                tabIndex="1"
                data-test="email-field"
                label={Translations.get(this.props.usernameLabel || label)}
                type={type}
                value={this.props?.loginObject?.userName?.value}
                onChange={e => {
                  if (zeroCorrectionFlag(e, SACorrectionLength)) {
                    /**
                     * If South African, and first number is 0, and 9 numbers are typed
                     * remove the zero. THIS IS BAD PRACTISE BUT CLIENT REQUESTED.
                     * DO NOT ACTIVATE FOR ANY OTHER BRAND OR REPLICATE WITHOUT CONTACTING
                     * SOMEONE SENIOR AND GETTING THIS IS IN WRITING.
                     */
                    e.target.value = e.target.value.substr(1);
                  }
                  this.setState(prevState => {
                    return {
                      ...prevState,
                      username: e.target.value,
                    };
                  });
                  this.props.fieldChanged(e, GLOBAL_CONSTANTS.USERNAME);
                }}
                info={Translations.get(
                  this.props?.loginObject?.userName?.error
                )}
                status={
                  this.props?.loginObject?.userName?.error ? 'error' : 'null'
                }
                focus={this.state.focusFields && this.state.showModal}
              />
              <IT
                tabIndex="2"
                autoComplete="current-password"
                type={this.state.showPassword ? 'text' : 'password'}
                data-test="password-field"
                label={Translations.get('user.password')}
                value={this.props?.loginObject?.passWord?.value}
                placeholder={this.props?.appConfig?.placeholder}
                onChange={e =>
                  this.props.fieldChanged(e, GLOBAL_CONSTANTS.PASSWORD)
                }
                info={Translations.get(
                  this.props?.loginObject?.passWord?.error
                )}
                status={
                  this.props?.loginObject?.passWord?.error ? 'error' : 'null'
                }
                helperText={
                  !forgotPasswordBelow &&
                  Translations.get('text.forgot.password')
                }
                helperLink={!forgotPasswordBelow && GLOBAL_PATH.FORGOT_PASSWORD}
                focus={this.state.focusFields && this.state.showModal}
                flexAlign={true}
                withIcon={this.props.showJNModal}
                iconClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
                isSafari={this.state.isSafari}
                defaultIcon={GLOBAL_CONSTANTS.OPEN_EYE}
                toggleIcon={GLOBAL_CONSTANTS.CLOSED_EYE}
                defaultIconState={!this.state.showPassword}
                iconSize={24}
              />
              {forgotPasswordBelow && (
                <CR>
                  <Link href={GLOBAL_PATH.FORGOT_PASSWORD} tabIndex="4">
                    {Translations.get('text.forgot.password')}
                  </Link>
                </CR>
              )}
              {this.props?.loginObject?.errorMessage && (
                <E
                  dangerouslySetInnerHTML={{
                    __html: this.props.loginObject.errorMessage,
                  }}
                ></E>
              )}
              {additionalLoginInfo && (
                <CB>
                  <input
                    type="checkbox"
                    onChange={() =>
                      this.setState({ checked: !this.state.checked })
                    }
                    checked={this.state.checked}
                  />
                  <label>{Translations.get('login.checkbox')}</label>
                </CB>
              )}
              <ModalButton
                tabIndex="3"
                disabled={
                  !additionalLoginInfo
                    ? this.props?.loginObject?.userName?.value?.length > 0 &&
                      this.props?.loginObject?.passWord?.value.length > 0 &&
                      false
                    : !enableLoginButton ||
                      !this.props.loginObject.isLoginEnable
                } // TODO
                data-test="btn-field"
                aria-label="submit login"
                label={
                  <>
                    {Translations.get('text.log.in')}
                    {!this.props.loginObject.isLoginEnable && <Spinner />}
                  </>
                }
                onClick={() => {
                  this.props.loginObject.isLoginEnable &&
                    this.props.callLogin(
                      this.props?.loginObject,
                      this.props.appConfig,
                      {
                        isbetgtmtag: this.props.appConfig?.isbetgtmtag,
                        redirectToCasinoPage: redirectToCasinoPage,
                        isHio:
                          (this.props.appConfig?.validatedata &&
                            this.props.appConfig?.validatedata) ||
                          false,
                        usernamePrefix: this.props.usernamePrefixValue,
                        gameUrl: this.state.gameUrl,
                        usernameValidationKey:
                          this.props.usernameValidationKey || '',
                      },
                      {},
                      this.state.username,
                      this.props.appConfig?.formatemailtolowercase
                    );

                  getCookie('AppSession') &&
                    PubSub.emit(
                      PubsubEvents.OpenResponsibleModalPopUpLogin,
                      true
                    );
                }}
              />
              <D>
                <S>{Translations.get('text.or')}</S>
              </D>
              <ModalButton
                showJNModal={this.props.showJNModal}
                data-test="btn-join"
                onClick={() => this.props.callJoinNow()}
                label={
                  this.props.showJNModal
                    ? Translations.get('text.join')
                    : Translations.get('text.join.now')
                }
                rank="secondary"
              />
              {!this.props.appConfig?.showhelp && (
                <C>
                  {Translations.get('text.need.help')}
                  {!this.props.appConfig?.hidectc && (
                    <Link
                      href={
                        this.props.appConfig?.contactusurl
                          ? this.props.appConfig?.contactusurl
                          : GLOBAL_PATH.CONTACT_US
                      }
                    >
                      {Translations.get('text.contact.us')}
                    </Link>
                  )}
                </C>
              )}
            </form>
          </Modal>
        )}
      </FSBTheme>
    );

    const mfaVerifyModal = (
      <FSBTheme theme={this.state.theme}>
        <Modal
          data-test="close-modal"
          open={
            this.props.loginObject.returnCode === 'MFA_REQUIRED' &&
            this.props.showModal
          }
          onClose={() =>
            this.props.onModalClick(false, '', redirectToCasinoPage)
          }
          center
          styles={MStyles}
          closeIcon={<CloseIcon color={this.props?.color} />}
        >
          <MfaVerify
            pinChange={this.props.fieldChanged}
            callLogin={this.props.callLogin}
            loginObject={this.props?.loginObject}
            appConfig={this.props?.appConfig}
          />
        </Modal>
      </FSBTheme>
    );

    if (this.props.loginObject.registrationSuccess && this.state.userLoggedIn)
      return registrationSuccessModal;
    if (enableResetModal && this.props.loginObject.resetSuccess)
      return resetSuccessModal;
    if (this.props.loginObject.returnCode === 'MFA_REQUIRED')
      return mfaVerifyModal;
    return loginModal;
  }
}

LoginModal.propTypes = {
  onModalClick: PropTypes.func,
  showModal: PropTypes.bool,
  isLoginEnable: PropTypes.bool,
  loginObject: PropTypes.object,
  fieldChanged: PropTypes.func,
  callLogin: PropTypes.func,
  callJoinNow: PropTypes.func,
  openPasswordModal: PropTypes.func,
  setRegistrationSuccess: PropTypes.func,
  setResetSuccess: PropTypes.func,
  onRegisterModalClick: PropTypes.func,
  onResetModalClick: PropTypes.func,
  showJNModal: PropTypes.bool,
  showPassword: PropTypes.bool,
  darkTheme: PropTypes.bool,
  appConfig: PropTypes.object,
  theme: PropTypes.string,
  successRedirectText: PropTypes.string,
  color: PropTypes.string,
  usernamePrefix: PropTypes.string,
  usernamePrefixValue: PropTypes.string,
  usernameLabel: PropTypes.string,
  usernameValidationKey: PropTypes.string,
};
export default LoginModal;
